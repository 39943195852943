<script lang="ts" setup>
const props = defineProps<{ id: string }>()

const { accountQuery } = useQuery()
const { data: accountStats, isLoading } = accountQuery.findStats(props.id)

function getStatClass(count: number) {
  if (count === 0) {
    return 'StatNone'
  }
  return 'Stat'
}
</script>

<template>
  <TheLoader v-if="isLoading" />
  <ul v-else-if="accountStats" class="list-disc list-inside">
    <li :class="getStatClass(accountStats.subPersonCount)">
      {{ $t('account.stats.subPerson', accountStats.subPersonCount) }}
    </li>
    <li :class="getStatClass(accountStats.caseCount)">
      {{ $t('account.stats.cases', accountStats.caseCount) }}
    </li>
    <li :class="getStatClass(accountStats.documentCount)">
      {{ $t('account.stats.documents', accountStats.documentCount) }}
    </li>
    <li :class="getStatClass(accountStats.vaccinationCount)">
      {{ $t('account.stats.vaccinations', accountStats.vaccinationCount) }}
    </li>
    <li :class="getStatClass(accountStats.immunizationCount)">
      {{ $t('account.stats.immunizations', accountStats.immunizationCount) }}
    </li>
    <li :class="getStatClass(accountStats.caseEventCount)">
      {{ $t('account.stats.caseEvents', accountStats.caseEventCount) }}
    </li>
  </ul>
</template>

<style scoped>
.StatNone { @apply text-gray-600 font-light; }
.Stat { @apply font-semibold; }
</style>
